//jshint esversion:8

import { isBDay } from "./ext/openDate.js";
import setPage from "./ext/setPage.js";
import { late, soon } from "./pages.js";
import { animate } from "./animation.js";
import { SplineViewer } from "@splinetool/viewer";

/******************************************************* SETUP ************************************************************/

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function getCurrentDateIST() {
  const now = new Date();
  now.setHours(now.getUTCHours() + 5); // Adjust for IST offset (5.5 hours ahead of UTC)
  now.setMinutes(now.getUTCMinutes() + 30); // Adjust for IST offset (5.5 hours ahead of UTC)
  return now;
}

function isJulySixthIST() {
  const currentDate = getCurrentDateIST();
  console.log(currentDate);
  const targetDate = new Date(2024, 6, 5, 23, 0); // Year, Month (0-indexed), Day
  console.log(targetDate);
  console.log(currentDate >= targetDate);
  return (
    currentDate >= targetDate
  );
}

// console.log(isJulySixthIST());

// const status = isBDay()

// if (isJulySixthIST) {
//   status === "ON_TIME"
// }
window.onload = function () {
  if (document.readyState === "complete") {
    // Rest of your code here...
    if (isJulySixthIST()) {
      // const status = isBDay();
      // if (status === "IS_EARLY") setPage(soon);
      // if (status === "IS_LATE") setPage(late);
      // if (status === "ON_TIME") {
      sleep(30000).then(() => {
        document.getElementById("loading").style.display = "none";
        animate();
      });
      // };
    } else {
      setPage(soon);
    }
  }
};


