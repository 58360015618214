export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1>Whoa there! Someone's eager to start the celebration early!</h1>
    <br /><br /><br />
    <h2>
        Hari, it looks like you're a bit too excited to wait until midnight!
    </h2><br /><h2>Patience, my dear. Good things come to those who wait... just a few more hours!</h2>
    <br /><br /><br />
    <h2 style="color: red">Open at 5th July 11:50 PM</h2>
    <br /><br /><br />
    <h2 style="color: red">Best if seen on laptop or tablet</h2>`,
};

export const late = {
  title: "See you next time...",
  body: `<br />
    <h1>The party was over</h1>
    <br /><br /><br />
    <p>
        Yes, my gift for you is kinda simple, cheap, and weird ? &#128534<br>
        B-but. It's only for you. &#128150
    </p>
`,
};
